/* eslint-disable @typescript-eslint/no-explicit-any */
import NextHead from 'next/head';
import {config} from '@fortawesome/fontawesome-svg-core';
import Bugsnag from 'bugsnag';
import {AppShell} from 'components/AppShell';
import {I18nProvider} from '@halp/foundation/i18n';
import {Head} from '@halp/ui';
import {AppProvider} from '@halp/foundation/App';
import type {SessionUser} from '@halp/foundation/Users';
import type {AppProps} from 'next/app';
import '@fortawesome/fontawesome-svg-core/styles.css';
import '@halp/css';

config.autoAddCss = false; // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

export default function Halp({Component, pageProps, router}: AppProps) {
	const ErrorBoundary = Bugsnag.getPlugin('react') as any;

	return (
		<ErrorBoundary>
			<I18nProvider
				lngDict={pageProps.lngDict}
				locale={router.locale ?? 'en'}
				onError={Bugsnag.notify}
			>
				<AppProvider
					logoutMessage="See you next time!"
					dehydratedState={pageProps.dehydratedState}
					onUserLoaded={(user: SessionUser) => {
						Bugsnag.setUser(
							user.uid ?? undefined,
							user.email ?? undefined,
							user.name ?? undefined,
						);
						Bugsnag.addFeatureFlags(
							user.enabledFeatures.map((feature) => ({name: feature})),
						);
					}}
				>
					<Head />
					<NextHead>
						<meta charSet="utf-8" />
						<meta httpEquiv="X-UA-Compatible" content="IE=edge" />
						<meta
							name="viewport"
							content="width=device-width, initial-scale=1.0"
						/>
						<meta
							name="facebook-domain-verification"
							content="qta2vl7sfmaokxyvvup79o6r0h2oml"
						/>
						<link rel="icon" type="image/png" href="/favicon.png" />
					</NextHead>
					<AppShell>
						<Component {...pageProps} />
					</AppShell>
					<div id="modal-root" />
					<div id="portal-root" />
				</AppProvider>
			</I18nProvider>
		</ErrorBoundary>
	);
}
