import {Image, Heading, Stack} from '@halp/ui';
import {UserMenu, useUser} from '@halp/foundation/Users';
import {imgLogo} from '@halp/images';
import style from './TopNav.module.css';

export function TopNav() {
	const {user} = useUser();

	return (
		<Stack direction="row" justifyContent="space-between" alignItems="center">
			<Stack direction="row" spacing="md">
				<Image
					alt="Halp logo"
					loading="eager"
					className={style.Logo}
					src={imgLogo}
				/>
				<Heading spacing="lg">Halp Partner Dashboard</Heading>
			</Stack>
			{user ? <UserMenu /> : null}
		</Stack>
	);
}
